<template>
  <div class="p-6 flex flex-col items-center justify-start min-h-[75vh]">
    <div class="container max-w-screen-lg mx-auto">
      <div class="">
        <div class="bg-white rounded shadow-lg p-4 px-4 md:p-8 mb-6">
          <div class="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3">
            <div class="text-left hidden md:block">
              <p class="mb-4 font-medium text-lg text-gray-900">
                {{ descriptionTitle }}
              </p>
              <p class="text-gray-500">
                Campos com <span class="text-red-500">*</span> são obrigatórios
              </p>
            </div>

            <div id="form-fields" class="lg:col-span-2">
              <div
                class="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-5"
              >
                <div class="md:col-span-5">
                  <label class="text-left flex pl-1" for="full_name">
                    Funcionário <a class="text-red-600 ml-1">*</a>
                  </label>
                  <div
                    class="h-10 bg-gray-50 flex border border-gray-200 rounded items-center mt-1 w-full"
                  >
                    <SimpleSelect
                      class="px-1 py-2 appearance-none outline-none text-gray-800 w-full bg-transparen"
                      :data="employee"
                      @selected-data="
                        (val) => (store.newWithdraw.employee = val)
                      "
                    />
                  </div>

                  <span
                    name="newWithdraw.employee"
                    class="text-red-600 text-sm"
                  />
                </div>

                <div class="md:col-span-3">
                  <label class="text-left flex pl-1" for="full_name">
                    Informe o(s) item <a class="text-red-600 ml-1">*</a>
                  </label>
                  <div
                    class="h-10 bg-gray-50 flex border border-gray-200 rounded items-center mt-1 w-full"
                  >
                    <SimpleSelect
                      :data="stock"
                      @selected-data="(val) => mountItemsToSend(val)"
                      class="px-1 py-2 appearance-none outline-none text-gray-800 w-full bg-transparen"
                    />
                  </div>

                  <span name="newWithdraw.item" class="text-red-600 text-sm" />
                </div>

                <div class="md:col-span-2">
                  <label class="text-left flex pl-1" for="reason">
                    Motivo da retirada <a class="text-red-600 ml-1">*</a>
                  </label>
                  <div
                    class="h-10 bg-gray-50 flex border border-gray-200 rounded items-center mt-1"
                  >
                    <input
                      v-model="store.newWithdraw.reason"
                      name="reason"
                      :required="true"
                      class="px-1 py-2 appearance-none outline-none text-gray-800 w-full bg-transparent"
                    />
                    <CleanInputButton
                      @click="store.newWithdraw.reason = undefined"
                    />
                  </div>

                  <span
                    name="newWithdraw.reason"
                    class="text-red-600 text-sm"
                  />
                </div>
              </div>
              <div v-if="dataItems" class="mb-2 w-full p-1">
                <div
                  class="inline-flex flex-wrap"
                  v-for="(item, index) in dataItems"
                  :key="index"
                >
                  <ArrayItemsVue
                    :item="item.name"
                    @removeItem="removeItemByDataItems(index)"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="flex justify-center mt-8">
            <slot />
          </div>
        </div>
      </div>
    </div>
    <FloatBackPage />
  </div>
</template>

<script>
import FloatBackPage from '@/components/Buttons/FloatBackPage.vue'
import CleanInputButton from './helpers/CleanInputButton.vue'
import SimpleSelect from '@/components/Select/SimpleSelect.vue'
import { dataHome } from '../store/'
import { useToast } from 'vue-toastification'
import ArrayItemsVue from './helpers/ArrayItems.vue'

export default {
  name: 'FormVue',
  props: {
    descriptionTitle: String,
    employee: Array,
    stock: Array
  },
  components: {
    CleanInputButton,
    FloatBackPage,
    SimpleSelect,
    ArrayItemsVue
  },
  data () {
    const store = dataHome.state
    const toast = useToast()
    const dataItems = []

    return {
      store,
      toast,
      dataItems
    }
  },
  methods: {
    mountItemsToSend (item) {
      this.dataItems.push(item)
      this.store.newWithdraw.item = this.dataItems
    },
    removeItemByDataItems (index) {
      this.dataItems.splice(index, 1)
    }
  },
  mounted () {
    return (this.store.newWithdraw.company_id =
      localStorage.getItem('__company'))
  }
}
</script>

import axios from 'axios'
import { useToast } from 'vue-toastification'

export class PdfCreate {
  constructor(apiUrl) {
    this.apiUrl = apiUrl
    this.toast = useToast()
  }

  async fetchPdf(requestData = null) {
    try {
      const response = await axios({
        url: this.apiUrl,
        method: 'POST',
        data: requestData,
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/pdf',
          'Authorization': localStorage.getItem('__access'),
          '__company_id': localStorage.getItem('__company')
        }
      })

      const url = window.URL.createObjectURL(response.data)
      this.displayPdf(url);
    } catch (error) {
      this.toast('Algo deu errado ao emitir relatório', { toastClassName: 'toast-error' })
    }
  }

  displayPdf(pdfUrl) {
    window.open(pdfUrl, '_blank');
  }

}

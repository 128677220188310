<template>
  <div class="overflow-x-auto py-2 sm:px-6 lg:px-2 rounded-md">
    <ReportModal 
      :is-open="this.showModal"
      :modal-title="this.modalTitle"
      @modalIsClose="closeModal()"
    >
      <template #content>
        <Preview
          :employee="this.currentEmployee"
        />
      </template>
      <template #actions>
        <div class="flex justify-between w-full">
          <Create
            :label="'Relatório'"
            :icon="'fa-solid fa-file-invoice'"
            @click="generateReport(this.currentEmployee.id)"
            />
            <Create
            :label="'Crachá'"
            :icon="'fa-regular fa-id-badge'"
            @click="generateBadges(this.currentEmployee.id)"
          />
        </div>
      </template>
    </ReportModal>

    <table class="w-full">
      <slot />
      <tbody>
        <tr v-for="(row, index) in dataTable" :key="index" class="hover:shadow-lg hover:-translate-y-1 hover:-translate-x-1 transition-all z-9" :class="index % 2 === 0 ? 'bg-gray-100' : 'bg-white'">
          <td class="text-left whitespace-nowrap pr-3 text-sm text-gray-900 sm:pl-0 cursor-pointer min-w-[10px]">
            <div :class="{ 'text-center': center }">
              <span class="pl-2">
                {{ row['name'] }}
              </span>
            </div>
          </td>
          <td class="text-left whitespace-nowrap pr-3 text-sm text-gray-900 sm:pl-0 cursor-pointer min-w-[10px]">
            <div :class="{ 'text-center': center }">
              <span class="pl-2">
                {{ row['role'] }}
              </span>
            </div>
          </td>
          <td class="text-left whitespace-nowrap pr-3 text-sm text-gray-900 sm:pl-0 cursor-pointer min-w-[10px]">
            <div :class="{ 'text-center': center }">
              <span class="pl-2">
                {{ row['phone'] }}
              </span>
            </div>
          </td>
          <td class="text-left whitespace-nowrap pr-3 text-sm text-gray-900 sm:pl-0 cursor-pointer min-w-[10px]">
            <div :class="{ 'text-center': center }">
              <span class="pl-2">
                {{ row['rg'] }}
              </span>
            </div>
          </td>
          <td class="text-left whitespace-nowrap pr-3 text-sm text-gray-900 sm:pl-0 cursor-pointer min-w-[10px]">
            <div :class="{ 'text-center': center }">
              <span class="pl-2">
                {{ row['cpf'] }}
              </span>
            </div>
          </td>
          <td class="text-left whitespace-nowrap pr-3 text-sm text-gray-900 sm:pl-0 cursor-pointer min-w-[10px]">
            <div :class="{ 'text-center': center }">
              <span class="pl-2">
                {{ row['address'] }}
              </span>
            </div>
          </td>
          <td class="text-left whitespace-nowrap pr-3 text-sm text-gray-900 sm:pl-0 cursor-pointer min-w-[10px]">
            <div :class="{ 'text-center': center }">
              <span class="pl-2">
                {{ row['number'] }}
              </span>
            </div>
          </td>
          <td class="flex justify-center whitespace-nowrap py-1 pr-2 text-sm font-medium text-gray-900 sm:pl-0">
            <ActionsButtonVue
              :link="url.edit + dataTable[index].id"
              @delete="deleteEmployee(dataTable[index].id)"
              @getReport="showReport(dataTable[index])"
            />
            <!-- @getReport="generateReport(dataTable[index].id)" -->
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import ActionsButtonVue from './../../../components/Tables/ActionsButton.vue'
import EmployeesService, { PATH } from '../services/employees.service'
import ReportModal from '@/components/Modals/Reports.vue'
import Preview from './Report/Preview.vue'
import Create from '@/components/Buttons/Create.vue'
import { PdfCreate } from '@/utils/reports/generate-blob'
import { storeApp } from '@/store' 
import { useToast } from 'vue-toastification'

export default {
  name: 'EmployeesTable',
  components: {
    ActionsButtonVue,
    ReportModal,
    Preview,
    Create
  },
  props: {
    dataTable: [Object, Array],
    center: { Boolean, default: false }
  },
  data () {
    return {
      url: {
        edit: '/employees/edit/',
        delete: '/employees/delete/'
      },
      columns: this.dataTable,
      toast: useToast(),
      showModal: false,
      modalTitle: 'Selecione o relatório que deseja',
      currentEmployee: {}
    }
  },
  methods: {
    closeModal() {
      this.showModal = false
      this.currentEmployee = {}
    },
    showReport(employeeData) {
      this.currentEmployee = employeeData
      this.showModal = true
    },
    async generateReport (id) {
      storeApp.state.loading = true
      
      const url = `${process.env.VUE_APP_API_URL}${PATH}reports/${id}`
      const report = new PdfCreate(url)

      return await report.fetchPdf().finally(() => {
        storeApp.state.loading = false
      })
    },
    async generateBadges (id) {
      storeApp.state.loading = true
      
      const url = `${process.env.VUE_APP_API_URL}${PATH}${id}/badges`
      const report = new PdfCreate(url)

      return await report.fetchPdf().finally(() =>{
        storeApp.state.loading = false
      })
    },
    deleteEmployee (id) {
      storeApp.state.loading = true
      const confirm = window.confirm('Deseja realmente desativar esse funcionário ?')
      if (confirm) {
        EmployeesService.deleteEmployee(id).then(res => {
          if (res.data.success) { this.toast('Funcionário desativado com sucesso!', { toastClassName: 'toast-success' }) }
          window.location.reload()
        }).catch(err => {
          this.toast('Erro ao excluir item!', { toastClassName: 'toast-error' })
        })
      }
    }
  }
}
</script>

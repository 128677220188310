import { createStore } from 'vuex'

export const dataHome = createStore({
  state: () => {
    return {
      newWithdraw: {
        employee: undefined,
        item: undefined,
        reason: undefined,
        company_id: undefined
      }
    }
  },
  mutations: {
    reset (state) {
      state.newWithdraw.employee = undefined
      state.newWithdraw.item = undefined
      state.newWithdraw.reason = undefined
    }
  }
})

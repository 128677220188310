import { createStore } from 'vuex'

export const dataEmployee = createStore({
  state: () => {
    return {
      employee: {
        name: undefined,
        phone: undefined,
        document: {
          rg: undefined,
          cpf: undefined,
          cnh: undefined
        },
        email: undefined,
        address: {
          city: undefined,
          country: undefined,
          number: undefined
        },
        function: undefined,
        registeredSince: undefined,
        photo: null,
        imgPath: undefined,
        company_id: localStorage.getItem('__company')
      }
    }
  },
  mutations: {
    reset (state) {
      state.employee.address.city = undefined
      state.employee.address.county = undefined
      state.employee.address.number = undefined
      state.employee.document.cnh = undefined
      state.employee.document.cpf = undefined
      state.employee.document.rg = undefined
      state.employee.email = undefined
      state.employee.function = undefined
      state.employee.name = undefined
      state.employee.phone = undefined
      state.employee.photo = undefined
      state.employee.imgPath = undefined
      state.employee.registeredSince = undefined
    }
  }
})

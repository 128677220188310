<template>
  <div class="p-6 flex flex-col items-center justify-start min-h-[75vh]">
    <div class="container max-w-screen-lg mx-auto">
      <div class="">
        <div class="bg-white rounded shadow-lg p-4 px-4 md:p-8 mb-6">
          <div class="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3">
            <div class="text-left hidden md:block">
              <p class="mb-4 font-medium text-lg text-gray-900">
                {{ descriptionTitle }}
              </p>
              <p class="text-gray-500">
                Campos com <span class="text-red-500">*</span> são obrigatórios
              </p>
            </div>

            <div id="form-fields" class="lg:col-span-2">
              <div
                class="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-5"
              >
                <div class="md:col-span-5">
                  <label class="text-left flex pl-1" for="full_name">
                    Funcionário <a class="text-red-600 ml-1">*</a>
                  </label>
                  <div
                    class="h-10 bg-gray-50 flex border border-gray-200 rounded items-center mt-1 w-full"
                  >
                    <SimpleSelect
                      class="px-1 py-2 appearance-none outline-none text-gray-800 w-full bg-transparen"
                      :data="employee"
                      @selected-data="(val) => (store.state.employee = val)"
                    />
                  </div>

                  <span
                    name="employee"
                    class="text-red-600 text-sm"
                  />
                </div>

                <div class="md:col-span-3">
                  <label class="text-left flex pl-1" for="full_name">
                    Informe o(s) treinamentos <a class="text-red-600 ml-1">*</a>
                  </label>
                  <div
                    class="h-10 bg-gray-50 flex border border-gray-200 rounded items-center mt-1 w-full"
                  >
                    <SimpleSelect
                      :data="training"
                      class="px-1 py-2 appearance-none outline-none text-gray-800 w-full bg-transparen"
                      @selected-data="(val) => setTrainingsCollection(val)"
                    />
                  </div>

                  <span name="trainings" class="text-red-600 text-sm" />
                </div>

                <div class="md:col-span-2">
                  <label class="text-left flex pl-1" for="periodicity">
                    Informe a periodicidade <a class="text-red-600 ml-1">*</a>
                  </label>

                  <div
                    class="h-10 bg-gray-50 flex border border-gray-200 rounded items-center mt-1 w-full"
                  >
                    <InputSelect
                      class="px-1 py-2 appearance-none outline-none text-gray-800 w-full bg-transparen"
                      :data="comboFields"
                      @selected-data="
                        (val) => (store.state.periodicity = val.value)
                      "
                    />
                  </div>
                  <span name="periodicity" class="text-red-600 text-sm" />
                </div>
              </div>
              <div v-if="dataTrainings" class="mb-2 w-full p-1">
                <div
                  class="inline-flex flex-wrap"
                  v-for="(item, index) in dataTrainings"
                  :key="index"
                >
                  <ArrayItems
                    :item="item.name"
                    @removeItem="removeselectedItems(index)"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="flex justify-center mt-8">
            <slot />
          </div>
        </div>
      </div>
    </div>
    <!-- <FloatBackPage /> -->
  </div>
</template>

<script setup>
import SimpleSelect from '@/components/Select/SimpleSelect.vue'
import ArrayItems from '../utils/ArrayItems.vue'
import InputSelect from './../utils/InputSelect.vue'
import { dataTraining } from '../store'
import { daysToMonth } from '../utils/_partials/calcDayToMonth'
import { onMounted, ref, watch } from 'vue'
import TrainingService from '../service/training.service'
import { storeApp } from '@/store'

defineProps({
  descriptionTitle: String,
  training: [Object, Array],
  employee: [Object, Array]
})

const dataTrainings = ref([])
const store = dataTraining
const comboFields = ref([''])

watch(comboFields.value, setPeriodicitiesFields)

onMounted(async () => {
  await setPeriodicitiesFields()
})

function removeselectedItems (index) {
  dataTrainings.value.splice(index, 1)
}

function setTrainingsCollection (training) {
  console.log(training)
  dataTrainings.value.push(training)
  store.state.trainings = dataTrainings.value
}

async function setPeriodicitiesFields () {
  comboFields.value = []
  await TrainingService.getPeriods().then((res) => {
    res.data.forEach((p) => {
      const setMonths = daysToMonth(p.validity)
      comboFields.value.push({
        label:
          setMonths >= 6
            ? `Período de ${setMonths} meses`
            : `Período de ${setMonths} anos`,
        value: p.id
      })
    })
  })
}
</script>

<style></style>

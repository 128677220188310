<template>
  <div class="overflow-x-auto py-2 sm:px-6 lg:px-2 rounded-md">
    <ReportModal
      :is-open="this.showModal"
      :modal-title="this.modalTitle"
      @modalIsClose="closeModal()"
    >
      <template #content>
        <Preview 
          :item="this.currentItem" 
        />
      </template>
      <template #actions>
        <div class="flex justify-between w-full">
          <Create
            :label="'Geral'"
            :icon="'fa-solid fa-file-invoice'"
            @click="generateGeneralReport(this.currentItem.id)"
            />
            <Create
            :label="'Item atual'"
            :icon="'fa-solid fa-dolly'"
            @click="generateItemReport(this.currentItem.id)"
          />
        </div>
      </template>
    </ReportModal>

    <table class="w-full">
      <slot />
      <tbody>
        <tr v-for="(row, index) in dataTable" :key="index" class="hover:shadow-lg hover:-translate-y-1 hover:-translate-x-1 transition-all z-9" :class="index % 2 === 0 ? 'bg-gray-100' : 'bg-white'">
          <td class="text-left whitespace-nowrap pr-3 text-sm text-gray-900 sm:pl-0 cursor-pointer min-w-[10px]">
            <div :class="{ 'text-center': center }">
              <span class="pl-2">
                {{ row['name'] }}
              </span>
            </div>
          </td>
          <td class="text-left whitespace-nowrap pr-3 text-sm text-gray-900 sm:pl-0 cursor-pointer min-w-[10px]">
            <div :class="{ 'text-center': center }">
              <span class="pl-2 font-bold" :class="setStatusClass(row['inventory'])">
                {{ row['inventory'] }}
              </span>
            </div>
          </td>
          <td class="text-left whitespace-nowrap pr-3 text-sm text-gray-900 sm:pl-0 cursor-pointer min-w-[10px]">
            <div :class="{ 'text-center': center }">
              <span class="pl-2">
                {{ row['CA'] }}
              </span>
            </div>
          </td>
          <td class="text-left whitespace-nowrap pr-3 text-sm text-gray-900 sm:pl-0 cursor-pointer min-w-[10px]">
            <div :class="{ 'text-center': center }">
              <span class="pl-2">
                {{ row['validity'] }}
              </span>
            </div>
          </td>
          <td class="text-left whitespace-nowrap pr-3 text-sm text-gray-900 sm:pl-0 cursor-pointer min-w-[10px]">
            <div :class="{ 'text-center': center }">
              <span class="pl-2">
                {{ row['supplier'] }}
              </span>
            </div>
          </td>
          <td class="flex justify-center whitespace-nowrap py-1 pr-2 text-sm font-medium text-gray-900 sm:pl-0">
            <ActionsButtonVue
              :link="url.edit + dataTable[index].id"
              @delete="deleteIten(dataTable[index].id)"
              @getReport="showReport(dataTable[index])"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import ActionsButtonVue from './../../../components/Tables/ActionsButton.vue'
import StockService from './../services/stock.service'
import ReportModal from '@/components/Modals/Reports.vue'
import Create from '@/components/Buttons/Create.vue'
import Preview from '../layout/Report/Preview.vue'
import { storeApp } from '@/store'
import { useToast } from 'vue-toastification'
import { PdfCreate } from '@/utils/reports/generate-blob'
import { PATH } from './../services/stock.service'

export default {
  name: 'StockTable',
  components: {
    ActionsButtonVue,
    ReportModal,
    Create,
    Preview
  },
  props: {
    dataTable: [Object, Array],
    center: { Boolean, default: false }
  },
  data () {
    return {
      url: {
        edit: '/stock/edit/',
        delete: '/stock/delete/'
      },
      columns: this.dataTable,
      toast: useToast(),
      showModal: false,
      currentItem: {},
      modalTitle: 'Selecione o relatório que deseja',
    }
  },
  methods: {
    closeModal() {
      this.showModal = false
      this.currentEmployee = {}
    },
    showReport(itemData) {
      this.currentItem = itemData
      this.showModal = true
    },
    async generateItemReport (id) {
      storeApp.state.loading = true

      const url = `${process.env.VUE_APP_API_URL}${PATH}reports/${id}/item`
      const report = new PdfCreate(url)

      return await report.fetchPdf().finally(() => {
        storeApp.state.loading = false
      })
    },
    async generateGeneralReport (id) {
      storeApp.state.loading = true

      const url = `${process.env.VUE_APP_API_URL}${PATH}reports/${id}`
      const report = new PdfCreate(url)

      return await report.fetchPdf().finally(() => {
        storeApp.state.loading = false
      })
    },
    deleteIten (id) {
      storeApp.state.loading = true
      const confirm = window.confirm('Deseja realmente excluir esse item ?')
      if (confirm) {
        StockService.deleteIten(id).then(res => {
          if (res.data.success) {
            this.toast('Item excluido com sucesso!', { toastClassName: 'toast-success' })
          }
          window.location.reload()
        }).catch(err => {
          this.toast('Erro ao excluir item!', { toastClassName: 'toast-error' })
        })
      }
    },
    setStatusClass (amount) {
      if (amount <= 5) { return 'text-red-500' } else if (amount > 6 && amount <= 15) { return 'text-yellow-400' }
      return 'text-green-500'
    }
  }
}
</script>

import { createStore } from 'vuex'

export const dataUser = createStore({
  state: () => {
    return {
      company: {
        name: undefined,
        cnpj: undefined,
        city: undefined,
        address: undefined,
        country: undefined,
        number: undefined,
        photo: undefined
      },
      notifications: {
        email: undefined,
        period: undefined
      },
      imgPath: undefined
    }
  },
  mutations: {
    reset (state) {
      state.company.name = undefined
      state.company.cnpj = undefined
      state.company.city = undefined
      state.company.address = undefined
      state.company.country = undefined
      state.company.number = undefined
      state.company.photo = undefined
      state.notifications.email = undefined
      state.notifications.period = undefined
      state.imgPath = undefined
    }
  }
})

<template>
  <h1>
    Hello world
  </h1>
</template>

<script setup>
import { fingger } from '@/http/fingger'
import { onMounted } from 'vue'
import FingerprintJS from '@fingerprintjs/fingerprintjs'

const fpPrimisse = FingerprintJS.load()

onMounted(async () => {
  await initialize()
})

async function initialize () {
  const fp = await fpPrimisse
  const result = await fp.get()
}

</script>

<style>

</style>

<template>
  <div>
    <PageTitle> Nova retirada </PageTitle>
    <ContainerVue>
      <Tabs>
        <template #form>
          <Form
            :description-title="'Registro de nova retirada'"
            :employee="employeeData"
            :stock="stockData"
          >
            <CreateButton
              @click="newWithdraw()"
              :icon="'fa-regular fa-address-card'"
              :label="'Registrar'"
            />
          </Form>
        </template>

        <template #reports>
          <ReportsContainer :employees="employeeDataReport" :reports="reports" />
        </template>

        <template #history>
          <GoodTables
            :rows="rows"
          />
        </template>
      </Tabs>
    </ContainerVue>
  </div>
</template>

<script setup>
import ContainerVue from '@/components/Container.vue'
import PageTitle from '@/components/Titles/PageTitle.vue'
import ReportsContainer from '../components/ReportsContainer.vue'
import CreateButton from '@/components/Buttons/Create.vue'
import EmployeesService from '@/modules/employees/services/employees.service'
import StockService from '@/modules/stock/services/stock.service'
import HomeService from '../services/home.service'
import Form from './../components/Form.vue'
import Tabs from './../../../components/Tabs/TabsVue.vue'
import GoodTables from './../components/helpers/GoodTables.vue'
import { dataHome } from '../store'
import { useToast } from 'vue-toastification'
import { storeApp } from '@/store'
import { setFormFieldsError } from '@/helpers/formFieldsHandler'
import { onMounted, ref } from 'vue'
import * as yup from './../../../validator'

const store = dataHome
const toast = useToast()
const appStore = storeApp
const employeeData = ref([])
const employeeDataReport = ref([])
const stockData = ref([])
const reports = ref([])
let rows = ref()

const schema = yup.object().shape({
  newWithdraw: yup.object().shape({
    employee: yup.object().required().label('Funcionário'),
    item: yup.array().required().label('Item'),
    reason: yup.string().required().label('Motivo')
  })
})

onMounted(async () => {
  appStore.state.loading = true
  await Promise.all([
    getWithDraws(),
    getEmployeesData(),
    getEmployeesReport(),
    getItems(),
    getReports()
  ])
  appStore.state.loading = false
})

const getReports = async () => {
  await EmployeesService.getReports().then((res) => {
    reports.value = res.data
  })
}

const getWithDraws = async () => {
  await HomeService.getRecentWithdraw().then(res => {
    rows = res.data
  })
}

const getEmployeesData = async () => {
  const data = []
  await EmployeesService.getAllEmployees().then((res) => {
    res.data.forEach((element) => {
      data.push({
        id: element.id,
        name: element.name
      })
    })
  })
  employeeData.value = data
}

const getEmployeesReport = async () => {
  const data = []
  await EmployeesService.getAllEmployeesWithTrashed().then((res) => {
    res.data.forEach((element) => {
      data.push({
        id: element.id,
        name: element.name
      })
    })
  })
  employeeDataReport.value = data
}

const getItems = async () => {
  const data = []
  await StockService.getAllItens().then((res) => {
    res.data.forEach((element) => {
      data.push({
        id: element.id,
        name: element.name
      })
    })
  })
  stockData.value = data
}

async function newWithdraw () {
  appStore.state.loading = true

  const errors = []
  await schema.validate(store.state, { abortEarly: false }).catch((err) => {
    err.inner.forEach((e) => {
      errors.push({
        name: e.path,
        msg: e.message
      })
    })
  })

  if (errors.length > 0) {
    appStore.state.loading = false
    setFormFieldsError(errors)
    return
  }

  await HomeService.newWithdraw(store.state.newWithdraw)
    .then((res) => {
      appStore.state.loading = false
      if (res.data.success) {
        toast('Registro salvo com sucesso!', {
          toastClassName: 'toast-success'
        })

        getWithDraws()
        getReports()
      }
    })
    .catch((err) => {
      appStore.state.loading = false
      toast(
        err.response.data.msg ?? 'Algo deu errado, tente novamente mais tarde!',
        { toastClassName: 'toast-error' }
      )
    })
}

</script>

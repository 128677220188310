import { createRouter, createWebHistory } from 'vue-router'
import { homeRoute } from '@/modules/home/routes'
import { stockRoute } from '@/modules/stock/routes'
import { employeesRoute } from '@/modules/employees/routes'
import { loginRoute } from '@/modules/auth/routes'
import { storeApp } from '@/store'
import { http } from '@/http'
import { useToast } from 'vue-toastification'
import { profileRoute } from '@/modules/profile/routes'
import { companyRoute } from '@/modules/company/routes'
import { errorsPage } from '@/modules/errors/route'
import { finggerRoute } from '@/modules/fingger/route'
import { trainingRoute } from '@/modules/trainings/routes'

const toast = useToast()
const routes = [
  ...homeRoute,
  ...stockRoute,
  ...employeesRoute,
  ...loginRoute,
  ...profileRoute,
  ...companyRoute,
  ...errorsPage,
  ...finggerRoute,
  ...trainingRoute
]

const router = createRouter({
  history: createWebHistory('#'),
  routes
})

router.beforeEach(async (to, from, next) => {
  storeApp.state.loading = true
  if (to.name !== 'login') {
    try {
      await http.get(process.env.VUE_APP_API_URL.concat('auth/me'))
      storeApp.state.loading = false
      next()
    } catch (error) {
      toast(
        'Sessão expirada!', {
          toastClassName: 'toast-error'
        })
      storeApp.state.loading = false
      next({ name: 'login' })
    }
  } else {
    storeApp.state.accessToken.clear()
    storeApp.state.loading = false
    next()
  }
})

export default router

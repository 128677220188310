<template>
  <div class="flex flex-col justify-center">
    <div class="w-full max-w-2xl mx-auto bg-white rounded-md">
      <header class="px-5 py-4 flex items-center justify-between">
        <h2 class="font-semibold text-gray-800 text-xl">
          {{ header.title }}
        </h2>
        <!-- <p class="hidden w-16 lg:block text-gray-400">
          {{ header.itemsAmount }} itens
        </p> -->
      </header>

      <div class="p-1">
        <div class="overflow-x-auto">
          <table class="w-full">
            <tbody class="text-sm divide-y divide-gray-100">
              <tr
                v-for="(item, index) in content"
                :key="index"
                class="text-left hover:shadow-lg hover:-translate-y-1 hover:-translate-x-1 transition-all"
                :class="index % 2 === 0 ? 'bg-gray-50' : 'bg-white'"
              >
                <td class="p-1 whitespace-nowrap">
                  <div class="flex items-center">
                    <div class="w-10 h-10 flex-shrink-0 mr-2 sm:mr-3">
                      <img
                        class="rounded-full"
                        :src="
                          item.image
                            ? imgUrl + item.image
                            : 'https://img.freepik.com/vetores-premium/ilustracao-em-vetor-de-icone-de-contorno-de-construcao-de-capacete-de-seguranca_678192-2016.jpg'
                        "
                        style="width: 50px !important; height: 40px !important"
                        :alt="item.name"
                      />
                    </div>
                    <div class="font-medium text-gray-800">
                      {{ item.name.slice(0, 15).concat("...") }}
                    </div>
                  </div>
                </td>

                <td class="p-2 whitespace-nowrap">
                  <div
                    class="text-left font-bold"
                    :class="setStatusClass(item.inventory)"
                  >
                    {{ item.inventory }}
                  </div>
                </td>

                <td class="p-2 whitespace-nowrap">
                  <div class="text-center">
                    <span class="font-medium text-gray-800">CA: </span> {{ item.CA }}
                  </div>
                </td>

                <td class="p-2 whitespace-nowrap">
                  <div class="text-center">
                    {{ item.validity }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <NoDataAnim v-if="Object.keys(content).length === 0" />

        <div class="p-1 flex justify-end">
          <Link :link="header.link" :label="header.labelLink || 'Ver mais'" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import Link from './../../../components/Buttons/Link.vue'
import { defineProps } from 'vue'
import NoDataAnim from '@/components/Lotties/NoDataAnim.vue'

defineProps({
  header: {
    title: String,
    itemsAmount: String,
    link: String,
    labelLink: String
  },
  content: [Array, Object]
})

const imgUrl = process.env.VUE_APP_IMAGE_SERVE.concat('storage/')

function setStatusClass (amount) {
  if (amount <= 5) {
    return 'text-red-500'
  } else if (amount > 6 && amount <= 15) {
    return 'text-yellow-400'
  }
  return 'text-green-500'
}
</script>

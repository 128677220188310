<template>
  <div
    class="bg-white rounded shadow-lg p-4 px-4 grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3"
  >
    <div class="text-left">
      <p class="mb-4 font-medium text-lg text-gray-800">Vencimento Interno</p>
      <p class="text-gray-500 flex">
        <InfoVue
          :label="'Se nenhum periodo for informado, será definido um periodo de 15 dias'"
        />
        Informe uma data para realizar a troca desse epi após sua retirada
      </p>
    </div>

    <div class="lg:col-span-2 mt-3 md:mt-0">
      <div class="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-5">
        <div class="md:col-span-5">
          <label class="text-left flex pl-1" for="periodicity">
            Informe o periodo <a class="text-red-600 ml-1">*</a>
          </label>
          <div
            class="h-10 bg-gray-50 flex border border-gray-200 rounded items-center mt-1 w-full"
          >
            <InputSelect
              class="px-1 py-2 appearance-none outline-none text-gray-800 w-full bg-transparen"
              :data="comboOptions"
              @selected-data="(val) => (store.item.periodicity = val.value)"
            />
          </div>
          <span name="item.periodicity" class="text-red-600 text-sm" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import InfoVue from './../../../components/tooltip/InfoVue.vue'
import { dataStock } from '../store/'
import InputSelect from '@/components/Select/InputSelect.vue'

const store = dataStock.state

const props = defineProps({
  comboOptions: [Array, Object]
})

</script>

<template>
  <div>
    <Form
      :description-title="'Atribua treinamentos para membros da equipe'"
      :employee="employeeData"
      :training="trainingData"
    >
      <CreateButton
        @click="assignTraining()"
        :icon="'fa-solid fa-user-graduate'"
        :label="'Registrar'"
      />
    </Form>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import Form from './../components/Form.vue'
import CreateButton from '@/components/Buttons/Create.vue'
import EmployeesService from '@/modules/employees/services/employees.service'
import TrainingService from '../service/training.service'
import { dataTraining } from '../store'
import { useToast } from 'vue-toastification'
import { storeApp } from '@/store'
import { setFormFieldsError } from '@/helpers/formFieldsHandler'
import * as yup from './../../../validator'

const employeeData = ref([])
const trainingData = ref([])
const store = dataTraining.state
const toast = useToast()
const appStore = storeApp.state
const schema = yup.object().shape({
  trainings: yup.array().required().label('Treinamento'),
  employee: yup.object().required().label('Funcionário'),
  periodicity: yup.number().required().label('Periodicidade')
})

onMounted(async () => {
  appStore.loading = true
  await Promise.all([
    getEmployeesData(),
    getTrainingData()
  ])
  appStore.loading = false
})

const getEmployeesData = async () => {
  const dataEmployees = []
  await EmployeesService.getAllEmployees()
    .then((res) => {
      res.data.forEach((element) => {
        dataEmployees.push({
          id: element.id,
          name: element.name
        })
      })
    })
    .catch((err) => {
      toast('Algo deu errado ao buscar seus funcionários', {
        toastClassName: 'toast-error'
      })
    })
  employeeData.value = dataEmployees
}

const getTrainingData = async () => {
  const dataTrainings = []
  await TrainingService.getAllTrainings()
    .then((res) => {
      console.log(res)
      res.data.forEach((element) => {
        dataTrainings.push({
          id: element.id,
          name: element.name
        })
      })
    })
    .catch((err) => {
      toast('Algo deu errado ao buscar seus treinamentos', {
        toastClassName: 'toast-error'
      })
    })

  trainingData.value = dataTrainings
}

async function assignTraining () {
  appStore.loading = true

  const errors = []
  await schema.validate(store, { abortEarly: false }).catch((err) => {
    err.inner.forEach((e) => {
      errors.push({
        name: e.path,
        msg: e.message
      })
    })
  })

  if (errors.length > 0) {
    console.log(store, errors);
    appStore.loading = false
    setFormFieldsError(errors)
    return
  }

  await TrainingService.assignTraining(store)
    .then((res) => {
      console.log(res)
      if (res.data.success) {
        toast('Treinamento atribuido ao funcionário', {
          toastClassName: 'toast-success'
        })
      }
    })
    .catch((err) => {
      toast('Algo deu errado ao atribuir treinamento ao funcionário', {
        toastClassName: 'toast-warning'
      })
    })
    .finally(() => {
      appStore.loading = false
    })
}
</script>

<style scoped></style>

<template>
  <div>
    <PageTitleVue>
      Funcionários
    </PageTitleVue>

    <ContainerVue>
      <!-- <NoRecordsStatus
        v-if="!dataTable[0]"
      /> -->
      <EmployeesTable :dataTable="dataTable" :center="true">
        <thead>
          <tr>
            <TableHeadFieldVue :text-center="false">
              <span class="mb-1.5"> Nome </span>
            </TableHeadFieldVue>
            <TableHeadFieldVue :text-center="false">
              <span class="mb-1.5"> Função </span>
            </TableHeadFieldVue>
            <TableHeadFieldVue :text-center="false">
              <span class="mb-1.5"> Celular </span>
            </TableHeadFieldVue>
            <TableHeadFieldVue :text-center="false">
              <span class="mb-1.5"> RG </span>
            </TableHeadFieldVue>
            <TableHeadFieldVue :text-center="false">
              <span class="mb-1.5"> CPF </span>
            </TableHeadFieldVue>
            <TableHeadFieldVue :text-center="false">
              <span class="mb-1.5"> Endereço </span>
            </TableHeadFieldVue>
            <TableHeadFieldVue :text-center="false">
              <span class="mb-1.5"> Numero </span>
            </TableHeadFieldVue>
            <TableHeadFieldVue :textCenter="false">
              <span class="mb-1.5"> Ações </span>
            </TableHeadFieldVue>
          </tr>
        </thead>
      </EmployeesTable>
    </ContainerVue>
    <FloatCreateVue :link="'employees-new'" :icon="'fa-solid fa-user-plus'" />
  </div>
</template>

<script setup>
import PageTitleVue from '@/components/Titles/PageTitle.vue'
import ContainerVue from '@/components/Container.vue'
import EmployeesTable from './components/Table.vue'
import TableHeadFieldVue from '../../components/Tables/TableHeadField.vue'
import FloatCreateVue from '@/components/Buttons/FloatCreate.vue'
import { onBeforeMount, onMounted, ref } from 'vue'
import { storeApp } from '@/store'
import EmployeesService from './services/employees.service'
import { dataEmployee } from './store'
// import NoRecordsStatus from '@/components/StatusContent/NoRecordsStatus.vue'

const store = dataEmployee
const appStore = storeApp.state
const dataTable = ref(undefined)

onBeforeMount(() => {
  store.commit('reset')
})

onMounted(async () => {
  appStore.loading = true
  await getEmployees()
  appStore.loading = false
})

async function getEmployees () {
  appStore.loading = true
  return await EmployeesService.getAllEmployees().then(res => {
    dataTable.value = res.data
    appStore.loading = false
  }).catch(err => {
    appStore.loading = false
    console.log(err)
  })
}

</script>

<style></style>

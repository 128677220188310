export const contentType = [
  {
    label: 'Teórico',
    value: 'theoretical'
  },
  {
    label: 'Prático',
    value: 'practical'
  },
  {
    label: 'Teórico e Prático',
    value: 'theoretical_and_practical'
  }
]

export const category = [
  {
    label: 'Capacitação',
    value: 'capacitation'
  },
  {
    label: 'Reciclagem',
    value: 'recicle'
  }
]

class Lookups {
  addCurrentValue (value) {
    const currentPeriod = {
      label: `Periodo atual: ${value} dias`,
      value: Number(value)
    }
    periodicityField[6] = currentPeriod

    return periodicityField
  }
}

export default Lookups

const periodicityField = [
  { label: 'Periodo de 15 dias', value: 15 },
  { label: 'Periodo de 30 dias', value: 30 },
  { label: 'Periodo de 2 meses', value: 60 },
  { label: 'Periodo de 4 meses', value: 120 },
  { label: 'Periodo de 6 meses', value: 180 },
  { label: 'Periodo de 1 ano', value: 365 }
]

class Lookups {
  getComboPeriodicity () {
    return {
      periodicityField
    }
  }

  addCurrentValue (value) {
    const currentPeriod = {
      label: `Periodo atual: ${value} dias`,
      value: Number(value)
    }
    periodicityField[6] = currentPeriod

    return periodicityField
  }
}

export default Lookups

import { createStore } from 'vuex'

export const dataTraining = createStore({
  state: () => {
    return {
      trainings: undefined,
      employee: undefined,
      periodicity: undefined,
      trainingForm: {
        name: undefined,
        speaker: undefined,
        workload: undefined,
        periodicity_id: undefined,
        type: undefined,
        contentType: undefined
      }
    }
  },
  mutations: {
    reset (state) {
      state.trainingForm.name = undefined
      state.trainingForm.speaker = undefined
      state.trainingForm.workload = undefined
      state.trainingForm.periodicity_id = undefined
      state.trainingForm.type = undefined
      state.trainingForm.contentType = undefined
      state.trainingForm.period = undefined
    }
  }
})

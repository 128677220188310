<template>
  <div class="p-1 flex items-center justify-center" :key="store.trainingForm">
    <div class="container max-w-screen-lg mx-auto">
      <div>
        <div id="form-fields" class="md:p-8 mb-6">
          <div
            class="bg-white rounded shadow-lg p-4 px-4 grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3"
          >
            <div class="text-left hidden md:block">
              <p class="mb-4 font-medium text-lg text-gray-800">
                {{ props.formCreate.descriptionTitle }}
              </p>
              <p class="text-gray-500">
                Campos com <span class="text-red-500">*</span> são obrigatórios
              </p>
            </div>

            <div class="lg:col-span-2">
              <div
                class="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-5"
              >
                <div class="md:col-span-5">
                  <label class="text-left flex pl-1" for="name">
                    Título do treinamento <a class="text-red-600 ml-1">*</a>
                  </label>
                  <div
                    class="h-10 bg-gray-50 flex border border-gray-200 rounded items-center mt-1"
                  >
                    <input
                      type="text"
                      name="name"
                      v-model="store.trainingForm.name"
                      class="px-1 py-2 appearance-none outline-none text-gray-800 w-full bg-transparent"
                      required
                    />
                    <CleanInputButton
                      @click="store.trainingForm.name = undefined"
                    />
                  </div>

                  <span name="name" class="text-red-600 text-sm" />
                </div>
                
                <div class="md:col-span-5">
                  <label class="text-left flex pl-1" for="speaker">
                    Palestrante
                  </label>
                  <div
                    class="h-10 bg-gray-50 flex border border-gray-200 rounded items-center mt-1"
                  >
                    <input
                      type="text"
                      name="name"
                      v-model="store.trainingForm.speaker"
                      class="px-1 py-2 appearance-none outline-none text-gray-800 w-full bg-transparent"
                      required
                    />
                    <CleanInputButton
                      @click="store.trainingForm.speaker = undefined"
                    />
                  </div>

                  <span name="speaker" class="text-red-600 text-sm" />
                </div>

                <div class="md:col-span-3">
                  <label class="text-left flex pl-1" for="workload">
                    Carga horária
                    <a class="text-red-600 ml-1">*</a>
                  </label>
                  <div
                    class="h-10 bg-gray-50 flex border border-gray-200 rounded items-center mt-1"
                  >
                    <input
                      type="number"
                      name="workload"
                      v-model="store.trainingForm.workload"
                      required
                      class="px-1 py-2 appearance-none outline-none text-gray-800 w-full bg-transparent"
                    />
                    <CleanInputButton
                      @click="store.trainingForm.workload = undefined"
                    />
                  </div>

                  <span name="workload" class="text-red-600 text-sm" />
                </div>

                <div class="md:col-span-2">
                  <label class="text-left flex pl-1" for="periodicity_id">
                    Periodicidade
                    <a class="text-red-600 ml-1">*</a>
                  </label>
                  <div
                    class="h-10 bg-gray-50 flex border border-gray-200 rounded items-center mt-1"
                  >  
                    <SimpleSelect
                      :data="combo.periodicity"
                      :current="getPeriodicityByKey(combo.periodicity, store.trainingForm.period?.id) - 1 ?? 0"
                      @selected-data="
                        (val) => (store.trainingForm.periodicity_id = val.value)
                      "
                      class="px-1 py-2 appearance-none outline-none text-gray-800 w-full bg-transparen"
                    />
                  </div>

                  <span name="periodicity_id" class="text-red-600 text-sm" />
                </div>

                <div class="md:col-span-2">
                  <label class="text-left flex pl-1" for="type">
                    Categoria
                  </label>
                  <div
                    class="h-10 bg-gray-50 flex border border-gray-200 rounded items-center mt-1"
                  >
                    <SimpleSelect
                      :data="combo.category"
                      :current="getTypeByKey(combo.category, store.trainingForm.type) ?? 0"
                      @selected-data="
                        (val) => (store.trainingForm.type = val.value)
                      "
                      class="px-1 py-2 appearance-none outline-none text-gray-800 w-full bg-transparen"
                    />
                  </div>
                </div>

                <div class="md:col-span-3">
                  <label class="text-left flex pl-1" for="contentType">
                    Tipo do treinamento
                  </label>
                  <div
                    class="h-10 bg-gray-50 flex border border-gray-200 rounded items-center mt-1"
                  >
                    <SimpleSelect
                      :data="combo.contentType"
                      :current="getContentType(combo.contentType, store.trainingForm.contentType) ?? 0"
                      @selected-data="
                        (val) => (store.trainingForm.contentType = val.value)
                      "
                      class="px-1 py-2 appearance-none outline-none text-gray-800 w-full bg-transparen"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="p-2 mt-1 mb-1" />

            <div class="w-full flex justify-center mt-8">
              <CreateButton 
                @click="checkFormType()" 
                :icon="'fas fa-plus'"
                :label="props.formCreate.labelButton"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import CleanInputButton from '../utils/CleanInputButton.vue'
import CreateButton from '@/components/Buttons/Create.vue'
import SimpleSelect from './../utils/InputSelect.vue'
import TrainingService from '../service/training.service'
import { dataTraining } from '../store'
import { useToast } from 'vue-toastification'
import { defineProps } from 'vue'
import { storeApp } from '@/store'
import { setFormFieldsError } from '@/helpers/formFieldsHandler'
import { category, contentType } from '../lookups/form'
import * as yup from './../../../validator'
import { defineEmits } from 'vue'

const appStore = storeApp.state
const store = dataTraining.state
const emit = defineEmits()
const schema = yup.object().shape({
  name: yup.string().required().label('Titulo do treinamento'),
  workload: yup.string().required().label('Carga horária'),
  periodicity_id: yup.number().required().label('Periodicidade'),
  type: yup.string().required().label('Categoria'),
  contentType: yup.string().required().label('Tipo do treinamento')
})

const props = defineProps({
  periodicityField: [Array, Object],
  formCreate: Object
})

const combo = {
  contentType: contentType,
  category: category,
  periodicity: props.periodicityField
}

function getPeriodicityByKey(comboArray, value) {
  for (const [index, el] of comboArray.entries()) {
    if (el.value == Number(value)) {
      store.trainingForm.periodicity_id = el.value
      return el.value;
    }
  }
  return null;
}

function getTypeByKey(comboArray, value) {
  for (const [index, el] of comboArray.entries()) {
    if (el.value == value)
      return index;
  }
  return null;
}

function getContentType(comboArray, value) {
  for (const [index, el] of comboArray.entries()) {
    if (el.value == value)
      return index;
  }
  return null;
}

function checkFormType () {
  if(props.formCreate.create)
    return registerTraining()

  return updateTraining()
}

async function updateTraining() {
  const errors = []
  await schema
    .validate(store.trainingForm, { abortEarly: false })
    .catch((err) => {
      err.inner.forEach((e) => {
        errors.push({
          name: e.path,
          msg: e.message
        })
      })
    })

  if (errors.length > 0) {
    appStore.loading = false
    setFormFieldsError(errors)
    return
  }

  emit('update', store.trainingForm.id)
}

async function registerTraining () {
  store.trainingForm.type =
    store.trainingForm.type || combo.category[0].value

  store.trainingForm.contentType =
    store.trainingForm.contentType || combo.contentType[0].value

  store.trainingForm.periodicity_id =
    store.trainingForm.periodicity_id || combo.periodicity[0].value

  const errors = []
  await schema
    .validate(store.trainingForm, { abortEarly: false })
    .catch((err) => {
      err.inner.forEach((e) => {
        errors.push({
          name: e.path,
          msg: e.message
        })
      })
    })

  if (errors.length > 0) {
    appStore.loading = false
    setFormFieldsError(errors)
    return
  }

  emit('register')
}

</script>

<template>
  <div
    class="bg-white rounded shadow-lg p-4 px-4 grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3"
  >
    <div class="text-left">
      <p class="mb-4 font-medium text-lg text-gray-800">Notificações</p>
      <p class="text-gray-500 flex">
        <InfoVue
          :label="'Se nenhum e-mail for informado, seu e-mail de usuario será usado'"
        />
        Adicione um e-mail e um periodo para recber notificações e atualizações
        da sua empresa
      </p>
    </div>

    <div class="lg:col-span-2 mt-3 md:mt-0">
      <div class="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-5">
        <div class="md:col-span-4">
          <label class="text-left flex pl-1" for="name">
            Email <a class="text-red-600 ml-1">*</a>
          </label>

          <div
            class="h-10 bg-gray-50 flex border border-gray-200 rounded items-center mt-1"
          >
            <input
              v-model="store.notifications.email"
              type="email"
              name="notifications.email"
              class="px-1 py-2 appearance-none outline-none text-gray-800 w-full bg-transparent"
            />
            <CleanInputButton @click="store.notifications.email = undefined" />
          </div>

          <span name="notifications.email" class="text-red-600 text-sm" />
        </div>

        <div class="md:col-span-1">
          <label class="text-left flex pl-1" for="name">
            Período (dias)<a class="text-red-600 ml-1">*</a>
          </label>

          <div
            class="h-10 bg-gray-50 flex border border-gray-200 rounded items-center mt-1"
          >
            <input
              v-model="store.notifications.period"
              type="number"
              name="notifications.period"
              class="px-1 py-2 appearance-none outline-none text-gray-800 w-full bg-transparent text-center"
            />
            <CleanInputButton @click="store.notifications.period = undefined" />
          </div>

          <span name="notifications.period" class="text-red-600 text-sm" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import CleanInputButton from './../component/CleanInputButton.vue'
import InfoVue from './../../../components/tooltip/InfoVue.vue'
import { dataUser } from '../store/'

const store = dataUser.state

</script>

<style></style>

<template>
  <div>
    <RouterView class="router-view" v-slot="{ Component = Table }">
      <Transition mode="out-in">
        <component
          :is="currentComponent || Component"
          :dataTable="dataTrainings"
          :descriptionTitle="'Registre um novo treinamento'"
          :periodicityField="comboFields"
          :formCreate="formType"
          @getDataByTraining="getTrainingById"
          @register="addTraining"
          @update="updateTraining"
          @remove="removeTraining"
        />
      </Transition>
    </RouterView>
  </div>

  <FloatButton
    v-if="!setButton"
    @click.prevent="changeComponent(Form)"
    :title="'Adicionar treinamento'"
  />
  <FloatBackToComponent v-else @click.prevent="changeComponent(Table)" />
</template>

<script setup>
import Table from "../components/Table.vue";
import Form from "../components/NewTraining.vue";
import FloatButton from "./../components/_partials/FloatShowComponent.vue";
import FloatBackToComponent from "../components/_partials/FloatBackToComponent.vue";
import TrainingService from "./../service/training.service.js";
import { onMounted, ref } from "vue";
import { storeApp } from "@/store";
import { useToast } from "vue-toastification";
import { dataTraining } from "../store";
import { daysToMonth } from "../utils/_partials/calcDayToMonth";

const formType = ref({})
const store = dataTraining;
const appStore = storeApp.state;
const toast = useToast();
const setButton = ref(false);
const comboFields = ref([""]);
const dataTrainings = ref([]);
const currentComponent = ref(Table);
const currentItemId = ref(undefined)

const changeComponent = (componentName) => {
  formType.value = {
    create: true,
    labelButton: 'Adicionar',
    descriptionTitle: 'Registrar novo treinamento'
  }
  store.commit("reset");
  setButton.value = !setButton.value;
  currentComponent.value = componentName;
};

onMounted(async () => {
  appStore.loading = true;
  await Promise.all([getTrainings(), setPeriodicitiesFields()]);
  appStore.loading = false;
});

async function getTrainings() {
  await TrainingService.getAllTrainings()
    .then((res) => {
      dataTrainings.value = res.data;
    })
    .catch((err) => {
      toast("Algo deu errado ao buscar seus treinamentos", {
        toastClassName: "toast-error",
      });
    });
}

async function setPeriodicitiesFields() {
  comboFields.value.length = 0
  await TrainingService.getPeriods().then((res) => {
    res.data.forEach((p) => {
      const setMonths = daysToMonth(p.validity);
      comboFields.value.push({
        label:
          setMonths >= 6
            ? `Período de ${setMonths} meses`
            : `Período de ${setMonths} anos`,
        value: p.id,
      });
    });
  });
}

async function getTrainingById(id) {
  appStore.loading = true;
  currentItemId.value = id

  changeComponent(Form);

  formType.value = {
    create: false,
    labelButton: 'Atualizar',
    descriptionTitle: 'Atualize seu treinamento'
  }

  await TrainingService.getTrainingsById(id)
    .then((res) => {
      store.state.trainingForm = res.data;
    }).catch((err) => {
      toast(
        "Algo deu errado ao buscar dados do treinamento", {
        toastClassName: "toast-error",
      });
    }).finally(() => {
      appStore.loading = false
    });
}

async function updateTraining(id) {
  appStore.loading = true;

  delete store.state.trainingForm.period

  await TrainingService.updateTraining(id, store.state.trainingForm)
    .then(res => {
      if(res.data.success)
        toast(
          "Treinamento atualizado com sucesso", { 
          toastClassName: "toast-success" 
        })
      getTrainings();
    }).catch(err => {
      toast(
        "Algo deu errado ao atualizar treinamento", { 
        toastClassName: "toast-error"
      })
    }).finally(() => {
      appStore.loading = false;

    })
}

async function addTraining() {
  appStore.loading = true;

  await TrainingService.registerTraining(store.state.trainingForm)
    .then((res) => {
      if (res.data.success) {
        toast("Novo treinamento adicionado", {
          toastClassName: "toast-success",
        });
      }
      getTrainings();
    })
    .catch((err) => {
      toast("Algo deu errado ao registrar treinamento", {
        toastClassName: "toast-error",
      });
    })
    .finally(() => {
      appStore.loading = false;
    });
}

async function removeTraining(id) {
  appStore.loading = true;
  const confirm = window.confirm("Deseja realmente remover esse treinamento ?");

  if (confirm) {
    await TrainingService.removeTraining(id)
      .then((res) => {
        if (res.data.success) 
          toast("Treinamento removido com sucesso !", {
            toastClassName: "toast-success",
          });
        getTrainings();
      })
      .catch((err) => {
        toast("Algo deu errado ao remover treinamento !", {
          toastClassName: "toast-error",
        });
      })
      .finally(() => {
        appStore.loading = false;
      });
  }
}
</script>

<template>
  <div class="p-6 flex items-center justify-center">
    <div class="container max-w-screen-lg mx-auto">
      <div>
        <div class="bg-white rounded shadow-lg p-4 px-4 md:p-8 mb-6">
          <div class="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3">
            <div class="text-left hidden md:block">
              <p class="mb-4 font-medium text-lg text-gray-800">
                {{ descriptionTitle }}
              </p>
              <p class="text-gray-500">
                Campos com <span class="text-red-500">*</span> são obrigatórios
              </p>
              <div class="flex justify-center">
                <img
                  v-if="selectedFileUrl || store.employee.imgPath"
                  :src="selectedFileUrl ?? fileUrl + store.employee.imgPath"
                  alt="Funcionário"
                  class="mt-14 mb-auto rounded-md w-60 max-h-60 hidden md:block"
                />
              </div>
            </div>

            <div id="form-fields" class="lg:col-span-2">
              <div
                class="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-5"
              >
                <div class="md:col-span-5">
                  <label class="text-left flex pl-1" for="name">
                    Nome Completo <a class="text-red-600 ml-1">*</a>
                  </label>
                  <div
                    class="h-10 bg-gray-50 flex border border-gray-200 rounded items-center mt-1"
                  >
                    <input
                      v-model="store.employee.name"
                      type="text"
                      name="name"
                      class="px-1 py-2 appearance-none outline-none text-gray-800 w-full bg-transparent"
                    />
                    <CleanInputButton
                      @click="store.employee.name = undefined"
                    />
                  </div>

                  <span name="employee.name" class="text-red-600 text-sm" />
                </div>

                <div class="md:col-span-2">
                  <label class="text-left flex pl-1" for="rg">RG</label>
                  <div
                    class="h-10 bg-gray-50 flex border border-gray-200 rounded items-center mt-1"
                  >
                    <input
                      v-model="store.employee.document.rg"
                      name="rg"
                      class="px-1 py-2 appearance-none outline-none text-gray-800 w-full bg-transparent"
                    />
                    <CleanInputButton
                      @click="store.employee.document.rg = undefined"
                    />
                  </div>
                </div>

                <div class="md:col-span-2">
                  <label class="text-left flex pl-1" for="cpf"
                    >CPF <a class="text-red-600 ml-1">*</a></label
                  >
                  <div
                    class="h-10 bg-gray-50 flex border border-gray-200 rounded items-center mt-1"
                  >
                    <input
                      v-mask="'###.###.###-##'"
                      v-model="store.employee.document.cpf"
                      name="cpf"
                      class="px-1 py-2 appearance-none outline-none text-gray-800 w-full bg-transparent"
                    />
                    <CleanInputButton
                      @click="store.employee.document.cpf = undefined"
                    />
                  </div>

                  <span
                    name="employee.document.cpf"
                    class="text-red-600 text-sm"
                  />
                </div>

                <div class="md:col-span-1">
                  <label class="text-left flex pl-1" for="cnh"
                    >CNH (Tipo)</label
                  >
                  <div
                    class="h-10 bg-gray-50 flex border border-gray-200 rounded items-center mt-1"
                  >
                    <input
                      v-model="store.employee.document.cnh"
                      type="text"
                      name="cnh"
                      placeholder="AB"
                      class="px-1 py-2 appearance-none outline-none text-gray-800 w-full bg-transparent"
                    />
                    <CleanInputButton
                      @click="store.employee.document.cnh = undefined"
                    />
                  </div>

                  <span
                    name="employee.document.cnh"
                    class="text-red-600 text-sm"
                  />
                </div>

                <div class="md:col-span-3">
                  <label class="text-left flex pl-1" for="email">Email</label>
                  <div
                    class="h-10 bg-gray-50 flex border border-gray-200 rounded items-center mt-1"
                  >
                    <input
                      v-model="store.employee.email"
                      type="text"
                      name="email"
                      class="px-1 py-2 appearance-none outline-none text-gray-800 w-full bg-transparent"
                      placeholder="email@domain.com"
                    />
                    <CleanInputButton
                      @click="store.employee.email = undefined"
                    />
                  </div>
                </div>

                <div class="md:col-span-2">
                  <label class="text-left flex pl-1" for="phone"
                    >Celular <a class="text-red-600 ml-1">*</a></label
                  >
                  <div
                    class="h-10 bg-gray-50 flex border border-gray-200 rounded items-center mt-1"
                  >
                    <input
                      v-model="store.employee.phone"
                      type="text"
                      v-mask="'(##) # ####-####'"
                      name="phone"
                      class="px-1 py-2 appearance-none outline-none text-gray-800 w-full bg-transparent"
                    />
                    <CleanInputButton
                      @click="store.employee.phone = undefined"
                    />
                  </div>

                  <span name="employee.phone" class="text-red-600 text-sm" />
                </div>

                <div class="md:col-span-3">
                  <label class="text-left flex pl-1" for="function"
                    >Função <a class="text-red-600 ml-1">*</a></label
                  >
                  <div
                    class="h-10 bg-gray-50 flex border border-gray-200 rounded items-center mt-1"
                  >
                    <input
                      v-model="store.employee.function"
                      name="function"
                      placeholder="Aux. Limpeza"
                      class="px-1 py-2 appearance-none outline-none text-gray-800 w-full bg-transparent"
                    />
                    <CleanInputButton
                      @click="store.employee.function = undefined"
                    />
                  </div>

                  <span name="employee.function" class="text-red-600 text-sm" />
                </div>

                <div class="md:col-span-2">
                  <label class="text-left flex pl-1" for="registeredSince"
                    >Admissão
                  </label>
                  <div
                    class="h-10 bg-gray-50 flex border border-gray-200 rounded items-center mt-1"
                  >
                    <input
                      v-model="store.employee.registeredSince"
                      name="registeredSince"
                      v-mask="'##/##/####'"
                      placeholder="15/01/2010"
                      class="px-1 appearance-none outline-none text-gray-800 w-full bg-transparent"
                    />
                    <CleanInputButton
                      @click="store.employee.registeredSince = undefined"
                    />
                  </div>
                </div>

                <div class="md:col-span-2">
                  <label class="text-left flex pl-1" for="city"
                    >Cidade <a class="text-red-600 ml-1">*</a></label
                  >
                  <div
                    class="h-10 bg-gray-50 flex border border-gray-200 rounded items-center mt-1"
                  >
                    <input
                      v-model="store.employee.address.city"
                      type="text"
                      name="employee.address.city"
                      class="px-1 py-2 appearance-none outline-none text-gray-800 w-full bg-transparent"
                      placeholder=""
                    />
                    <CleanInputButton
                      @click="store.employee.address.city = undefined"
                    />
                  </div>

                  <span
                    name="employee.address.city"
                    class="text-red-600 text-sm"
                  />
                </div>

                <div class="md:col-span-2">
                  <label class="text-left flex pl-1" for="country"
                    >Bairro <a class="text-red-600 ml-1">*</a></label
                  >
                  <div
                    class="h-10 bg-gray-50 flex border border-gray-200 rounded items-center mt-1"
                  >
                    <input
                      v-model="store.employee.address.country"
                      type="text"
                      name="employee.address.country"
                      class="px-1 py-2 appearance-none outline-none text-gray-800 w-full bg-transparent"
                      placeholder=""
                    />
                    <CleanInputButton
                      @click="store.employee.address.country = undefined"
                    />
                  </div>

                  <span
                    name="employee.address.country"
                    class="text-red-600 text-sm"
                  />
                </div>

                <div class="md:col-span-1">
                  <label class="text-left flex pl-1" for="zipcode"
                    >Número</label
                  >
                  <div
                    class="h-10 bg-gray-50 flex border border-gray-200 rounded items-center mt-1"
                  >
                    <input
                      v-model="store.employee.address.number"
                      type="text"
                      name="number"
                      class="px-1 py-2 appearance-none outline-none text-gray-800 w-full bg-transparent"
                      placeholder=""
                    />
                    <CleanInputButton
                      @click="store.employee.address.number = undefined"
                    />
                  </div>
                </div>

                <div class="flex justify-center">
                  <img
                    v-if="selectedFileUrl || store.employee.imgPath"
                    :src="selectedFileUrl ?? fileUrl + store.employee.imgPath"
                    alt="Funcionário"
                    class="mt-2 rounded-md w-60 max-h-60 block md:hidden"
                  />
                </div>

                <div class="md:col-span-5 flex h-10">
                  <label
                    class="cursor-pointer bg-gray-100 hover:bg-gray-300 transition-all min-w-[10rem] pt-2.5 h-10 rounded-l-md rounded-r-none"
                  >
                    <i class="fa-solid fa-cloud-arrow-up"></i>
                    Foto do funcionário
                    <input
                      type="file"
                      class="hidden"
                      @change="handleFileChange"
                    />
                  </label>
                  <input
                    type="text"
                    style="padding-left: 10px !important"
                    class="border rounded w-full h-10 rounded-r-md rounded-l-none"
                    :value="selectedFileName"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="flex justify-center mt-8">
            <slot />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CleanInputButton from '../components/CleanInputButton.vue'
import { dataEmployee } from '../store/'
import { useToast } from 'vue-toastification'

export default {
  name: 'FormVue',
  components: {
    CleanInputButton
  },
  props: {
    descriptionTitle: String
  },
  data () {
    const store = dataEmployee.state
    const toast = useToast()
    const fileUrl = process.env.VUE_APP_IMAGE_SERVE + 'storage/'

    return {
      store,
      fileUrl,
      toast,
      selectedFileName: undefined,
      selectedFileUrl: undefined
    }
  },
  methods: {
    handleFileChange (event) {
      const file = event.target.files[0]
      if (file) {
        const allowedExtensions = ['webp', 'png', 'gif', 'jpeg', 'jpg']
        const fileExtension = file.name.split('.').pop().toLowerCase()

        if (allowedExtensions.includes(fileExtension)) {
          this.selectedFileName = file.name
          this.selectedFileUrl = URL.createObjectURL(file)
          this.store.employee.photo = file
        } else {
          this.selectedFileUrl = undefined
          this.selectedFileName = undefined
          this.store.employee.photo = undefined
          return this.toast('Tipo de arquivo não é válido !', {
            toastClassName: 'toast-error'
          })
        }
      }
    }
  }
}
</script>

import axios from 'axios'

const httpSlack = axios.create({
  baseURL: process.env.VUE_APP_SLACK_HOST,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${process.env.VUE_APP_SLACK_TOKEN}`
  }
})

httpSlack.interceptors.request.use(
  (config) => {
    return config
  },
  (error) => {
    console.log(`Erro: ${error}`)
    // Promise.reject(error)
  }
)

export { httpSlack }

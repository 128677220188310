import EmployeesView from '../EmployeesView.vue'
import EmployeesUpdate from '../layout/UpdateEmployees.vue'
import EmployeesRegister from '../layout/Register.vue'

export const employeesRoute = [
  {
    path: '/employees/',
    name: 'employees',
    component: EmployeesView,
    beforeEnter: (to, from, next) => {
      if (!localStorage.__access) next({ name: 'login' })
      next()
    }
  },
  {
    path: '/employees/edit/:id',
    name: 'employees-update',
    component: EmployeesUpdate,
    beforeEnter: (to, from, next) => {
      if (!localStorage.__access) next({ name: 'login' })
      next()
    }
  },
  {
    path: '/employees/new',
    name: 'employees-new',
    component: EmployeesRegister,
    beforeEnter: (to, from, next) => {
      if (!localStorage.__access) next({ name: 'login' })
      next()
    }
  }
]

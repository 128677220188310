import StockView from '../StockView.vue'
import StockEdit from '../layout/UpdateItem.vue'
import StockRegister from '../layout/Register.vue'

export const stockRoute = [
  {
    path: '/stock/',
    name: 'stock',
    component: StockView
  },
  {
    path: '/stock/edit/:id',
    name: 'stock-edit',
    component: StockEdit
  },
  {
    path: '/stock/new',
    name: 'stock-new',
    component: StockRegister
  }
]

<template>
  <div>
    <PageTitleVue> Adicionar Item </PageTitleVue>
    <ContainerVue class="webkit">
      <Form :description-title="'Detalhes do item'">
        <NotifyForm :combo-options="combobox" :key="combobox" />
        <div class="w-full flex justify-center mt-8">
          <CreateButton @click="addItem()" />
        </div>
      </Form>
    </ContainerVue>
    <FloatBackPage />
  </div>
</template>

<script setup>
import * as yup from './../../../validator'
import PageTitleVue from '@/components/Titles/PageTitle.vue'
import ContainerVue from '@/components/Container.vue'
import Form from './Form.vue'
import CreateButton from '@/components/Buttons/Create.vue'
import StockService from '../services/stock.service'
import NotifyForm from './NotifyForm.vue'
import FloatBackPage from '@/components/Buttons/FloatBackPage.vue'
import Lookups from './lookups'
import { dataStock } from '../store'
import { storeApp } from '@/store'
import { useToast } from 'vue-toastification'
import { onBeforeMount, ref } from 'vue'
import { setFormFieldsError } from '@/helpers/formFieldsHandler'
import { parse, isValid } from 'date-fns'

const toast = useToast()
const appStore = storeApp.state
const store = dataStock
const lookups = new Lookups()
let combobox = ref([])

const schema = yup.object().shape({
  item: yup.object().shape({
    name: yup.string().required().label('Nome do item').min(3),
    amount: yup.number().required().label('Estoque'),
    periodicity: yup
      .number()
      .test('is-not-zero', 'O número não pode ser zero', (value) => value !== 0)
      .required()
      .label('Periodo'),
    approvalCertificate: yup.object().shape({
      number: yup.number().required().label('CA'),
      validity: yup.string().required().label('Validade do CA')
    })
  })
})

onBeforeMount(() => {
  combobox = lookups.getComboPeriodicity().periodicityField
  store.commit('reset')
})

async function addItem () {
  store.state.item.periodicity = 
    store.state.item.periodicity == undefined ? 15 : store.state.item.periodicity
  
  appStore.loading = true
  
  const errors = []
  await schema.validate(store.state, { abortEarly: false }).catch((err) => {
    err.inner.forEach((e) => {
      errors.push({
        name: e.path,
        msg: e.message
      })
    })
  })

  if (errors.length > 0) {
    appStore.loading = false
    setFormFieldsError(errors)
    return
  }

  if (
    store.state.item.photo &&
    typeof store.state.item.photo === 'object' &&
    typeof store.state.item.imgPath === 'string'
  ) {
    await StockService.editImage(
      store.state.item.photo,
      store.state.item.imgPath
    )
      .then((res) => {
        store.state.item.imgPath = res.data
        store.state.item.photo = res.data
      })
      .catch((err) => {
        toast('Algo deu errado ao subir arquivo!', {
          toastClassName: 'toast-warning'
        })
      })
  } else if (
    store.state.item.photo &&
    typeof store.state.item.photo !== 'string'
  ) {
    await StockService.saveImage(store.state.item.photo)
      .then((res) => {
        store.state.item.imgPath = res.data
        store.state.item.photo = res.data
      })
      .catch((err) => {
        toast('Algo deu errado ao subir arquivo!', {
          toastClassName: 'toast-warning'
        })
      })
  } else {
    store.state.item.photo = store.state.item.imgPath
  }

  await StockService.register(store.state.item)
    .then((res) => {
      appStore.loading = false
      if (res.data.success) {
        toast(res.data.msg, { toastClassName: 'toast-success' })
      }
    })
    .catch((err) => {
      appStore.loading = false
      toast('Algo deu errado! Tente novamente mais tarde', {
        toastClassName: 'toast-error'
      })
    })
}
</script>

<style lang="scss">
.webkit {
  ::-webkit-scrollbar {
    height: 1px;
    width: 4px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: green;
    border-radius: 6px;
  }

  ::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    border-radius: 6px;
  }
}
</style>

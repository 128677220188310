import TrainingView from '../TrainingView.vue'
import Register from '../layout/Register.vue'
import UpdateTraining from '../layout/UpdateTraining.vue'

export const trainingRoute = [
  {
    path: '/trainings',
    name: 'training',
    component: TrainingView
  }
  // {
  //   path: '/trainings/register',
  //   name: 'training-register',
  //   component: Register
  // },
  // {
  //   path: '/trainings/edit/:id',
  //   name: 'training-edit',
  //   component: UpdateTraining
  // },
]

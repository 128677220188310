<template>
  <div class="flex flex-col justify-center">
    <div class="w-full max-w-2xl mx-auto bg-white rounded-md">
      <div class="p-3">
        <div class="overflow-x-auto">
          <table class="w-full">
            <tbody class="text-sm divide-y divide-gray-100">
              <tr v-for="(item, index) in content" :key="index"
                class="text-left hover:shadow-lg hover:-translate-y-1 hover:-translate-x-1 transition-all"
                :class="index % 2 === 0 ? 'bg-gray-50' : 'bg-white'">

                <td class="p-1 whitespace-nowrap">
                  <div class="flex items-center w-10 mr-5">
                    <div class="w-10 h-10 flex-shrink-0 mr-2 sm:mr-3">
                      <img class="rounded-full" :src="item.photo ? imgUrl + item.photo :
                          'https://img.freepik.com/premium-vector/user-profile-icon-flat-style-member-avatar-vector-illustration-isolated-background-human-permission-sign-business-concept_157943-15752.jpg'
                        " style="width: 50px !important; height: 40px !important;" :alt="item.name">
                    </div>
                    <div class="font-medium text-gray-800">
                      {{ item.name.slice(0, 20).concat('...') }}
                    </div>
                  </div>
                </td>

                <td class="p-2 whitespace-nowrap w-5">
                  <p :style="{ 'background-color': item.colorStatus }" class="rounded-circle p-3 mr-auto" />
                </td>

                <td class="p-2 whitespace-nowrap">
                  <div class="text-center">
                    {{ item.amount }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps } from 'vue'

defineProps({
  content: [Array, Object]
})

const imgUrl = process.env.VUE_APP_IMAGE_SERVE.concat('storage/')

</script>

<style lang="scss">
.rounded-circle {
  width: 10px;
  border-radius: 50%;
}
</style>

import HomeView from './../HomeView.vue'
import RequestView from './../layout/ItenRequestView.vue'

function randValue () {
  const rand = Math.random()
  const encode = base64Encode(new TextEncoder().encode(rand))
  return encode
}

function base64Encode (bytes) {
  const binString = String.fromCodePoint(...bytes)
  return btoa(binString)
}

export const homeRoute = [
  {
    path: '/:random?',
    name: 'home',
    component: HomeView,
    props: (route) => ({ random: route.params.random || randValue() })
  },
  {
    path: '/new-request',
    name: 'new-request',
    component: RequestView
  }
]

<template>
  <div>
    <PageTitleVue>
      Inicio
    </PageTitleVue>
    <BlockContainerVue>
      <template #first>
        <RecentTable
          :header="firstTable"
          :content="firstContent"
        />
      </template>

      <template #second>
        <InventoryTable
          :header="secondTable"
          :content="secondContent"
        />
      </template>

      <template #third>
        <TitleChart>
          <div class="w-full text-center">
            CAMPEÕES DE RETIRADA
          </div>
        </TitleChart>

        <div v-if="Object.keys(thirdContent).length !== 0">
          <div class="flex justify-center items-center">
            <TheChartVue
              :colors="dataChart.color"
              :data="dataChart.amount"
              :labels="dataChart.label"
            />
          </div>

          <TheTableChartVue
            :header="thirdTable"
            :content="thirdContent"
          />
        </div>
        <div v-else>
          <NoDataAnim :width="300" :height="300" />
        </div>
      </template>
    </BlockContainerVue>
  </div>
</template>

<script>
import PageTitleVue from '@/components/Titles/PageTitle.vue'
import BlockContainerVue from './components/helpers/BlockContainer.vue'
import TheChartVue from './components/helpers/TheChart.vue'
import RecentTable from '@/modules/home/components/RecentTable.vue'
import TheTableChartVue from './components/TheTableChart.vue'
import TitleChart from './components/helpers/TitleChart.vue'
import HomeService from './services/home.service'
import InventoryTable from './components/InventoryTable.vue'
import { storeApp } from '@/store'
import { dataHome } from './store'
import NoDataAnim from '@/components/Lotties/NoDataAnim.vue'

export default {
  props: ['random'],
  components: {
    PageTitleVue,
    BlockContainerVue,
    RecentTable,
    InventoryTable,
    TheChartVue,
    TheTableChartVue,
    TitleChart,
    NoDataAnim
  },
  data () {
    return {
      store: dataHome,
      dataChart: {},
      firstTable: {
        title: 'ULTIMAS RETIRADAS',
        itemsAmount: '15',
        link: '/new-request',
        labelLink: 'Nova retirada'
      },
      firstContent: [],
      secondTable: {
        title: "ESTOQUE DE EPI's",
        itemsAmount: '25',
        link: '/stock'
      },
      secondContent: [],
      thirdTable: {
        title: 'CAMPEÕES DE RETIRADA',
        itemsAmount: '25',
        link: '#'
      },
      thirdContent: []
    }
  },
  methods: {
    async recentWithdraws () {
      await HomeService.getRecentWithdraw().then(res => {
        this.firstContent = res.data.slice(0, 4)
      })
    },
    async itensInventory () {
      await HomeService.getLowStock().then(res => {
        this.secondContent = res.data
      })
    },
    async retreatChampions () {
      await HomeService.getRetreatChampions().then(res => {
        this.thirdContent = res.data
      })
    },
    extractDataChart (responseData) {
      this.dataChart = {
        label: [],
        color: [],
        amount: []
      }

      responseData.forEach((item, index) => {
        this.dataChart.label.push(item.name.slice(0, 15).concat('...'))
        this.dataChart.color.push(item.colorStatus)
        this.dataChart.amount.push(item.amount)
      })

      return this.dataChart
    }
  },
  async beforeMount () {
    this.store.commit('reset')
  },
  async mounted () {
    storeApp.state.loading = true
    await Promise.all([
      this.recentWithdraws(),
      this.itensInventory(),
      this.retreatChampions()
    ])
    this.extractDataChart(this.thirdContent)
    storeApp.state.loading = false
  }
}
</script>

<style></style>

<template>
  <div class="p-6 flex items-center justify-center">
    <div class="container max-w-screen-lg mx-auto">
      <div>
        <div id="form-fields" class="md:p-8 mb-6">
          <div class="bg-white rounded shadow-lg p-4 px-4 grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3">
            <div class="text-left hidden md:block">
              <p class="mb-4 font-medium text-lg text-gray-800">
                {{ descriptionTitle }}
              </p>
              <p class="text-gray-500">
                Campos com <span class="text-red-500">*</span> são obrigatórios
              </p>
              <div class="flex justify-center">
                <img
                  v-if="selectedFileUrl || store.item.imgPath"
                  :src="selectedFileUrl ?? fileUrl + store.item.imgPath"
                  alt="EPI"
                  class="mt-14 mb-auto rounded-md w-60 max-h-60 hidden md:block"
                />
              </div>
            </div>

            <div class="lg:col-span-2">
              <div class="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-5">
                <div class="md:col-span-5">
                  <label class="text-left flex pl-1" for="full_name">
                    Nome do item <a class="text-red-600 ml-1">*</a>
                  </label>
                  <div
                    class="h-10 bg-gray-50 flex border border-gray-200 rounded items-center mt-1"
                  >
                    <input
                      v-model="store.item.name"
                      type="text"
                      name="item.name"
                      class="px-1 py-2 appearance-none outline-none text-gray-800 w-full bg-transparent"
                      required
                    />
                    <CleanInputButton @click="store.item.name = undefined" />
                  </div>

                  <span name="item.name" class="text-red-600 text-sm" />
                </div>

                <div class="md:col-span-5">
                  <label class="text-left flex pl-1" for="full_name">
                    Descrição
                  </label>
                  <div
                    class="h-10 bg-gray-50 flex border border-gray-200 rounded items-center mt-1"
                  >
                    <input
                      v-model="store.item.description"
                      type="text"
                      name="full_name"
                      class="px-1 py-2 appearance-none outline-none text-gray-800 w-full bg-transparent"
                    />
                    <CleanInputButton
                      @click="store.item.description = undefined"
                    />
                  </div>
                </div>

                <div class="md:col-span-3">
                  <label class="text-left flex pl-1" for="country">
                    Estoque atual
                    <a class="text-red-600 ml-1">*</a>
                  </label>
                  <div
                    class="h-10 bg-gray-50 flex border border-gray-200 rounded items-center mt-1"
                  >
                    <input
                      v-model="store.item.amount"
                      type="number"
                      name="item.amount"
                      required
                      class="px-1 py-2 appearance-none outline-none text-gray-800 w-full bg-transparent"
                    />
                    <CleanInputButton @click="store.item.amount = undefined" />
                  </div>

                  <span name="item.amount" class="text-red-600 text-sm" />
                </div>

                <div class="md:col-span-2">
                  <label class="text-left flex pl-1" for="email">
                    Valor unitário (R$)
                  </label>
                  <div
                    class="h-10 bg-gray-50 flex border border-gray-200 rounded items-center mt-1"
                  >
                    <input
                      type="number"
                      v-model="store.item.unit"
                      class="px-1 py-2 appearance-none outline-none text-gray-800 w-full bg-transparent"
                      placeholder="25"
                    />
                    <CleanInputButton @click="store.item.unit = undefined" />
                  </div>
                </div>

                <div class="md:col-span-2">
                  <label class="text-left flex pl-1" for="email">
                    Fabricante
                  </label>
                  <div
                    class="h-10 bg-gray-50 flex border border-gray-200 rounded items-center mt-1"
                  >
                    <input
                      type="text"
                      v-model="store.item.maker"
                      class="px-1 py-2 appearance-none outline-none text-gray-800 w-full bg-transparent"
                      placeholder="3M"
                    />
                    <CleanInputButton @click="store.item.maker = undefined" />
                  </div>
                </div>
                <div class="md:col-span-3">
                  <label class="text-left flex pl-1" for="email">
                    Fornecedor
                  </label>
                  <div
                    class="h-10 bg-gray-50 flex border border-gray-200 rounded items-center mt-1"
                  >
                    <input
                      type="text"
                      v-model="store.item.supplier"
                      class="px-1 py-2 appearance-none outline-none text-gray-800 w-full bg-transparent"
                      placeholder="Protege EPI's"
                    />
                    <CleanInputButton
                      @click="store.item.supplier = undefined"
                    />
                  </div>
                </div>

                <div class="md:col-span-1">
                  <label class="text-left flex pl-1" for="zipcode">
                    CA
                    <a class="text-red-600 ml-1">*</a>
                  </label>
                  <div
                    class="h-10 bg-gray-50 flex border border-gray-200 rounded items-center mt-1"
                  >
                    <input
                      type="text"
                      v-mask="'#####'"
                      v-model="store.item.approvalCertificate.number"
                      name="ca"
                      class="px-1 py-2 appearance-none outline-none text-gray-800 w-full bg-transparent"
                    />
                    <CleanInputButton
                      @click="store.item.approvalCertificate.number = undefined"
                    />
                  </div>

                  <span name="item.approvalCertificate.number" class="text-red-600 text-sm" />
                </div>

                <div class="md:col-span-2">
                  <label class="text-left flex pl-1" for="address">
                    Validade do CA <a class="text-red-600 ml-1">*</a>
                  </label>
                  <div
                    class="h-10 bg-gray-50 flex border border-gray-200 rounded items-center mt-1"
                  >
                    <input
                      v-model="store.item.approvalCertificate.validity"
                      name="item.approvalCertificate.validity"
                      v-mask="`##/##/####`"
                      class="px-1 py-2 appearance-none outline-none text-gray-800 w-full bg-transparent"
                      placeholder="00/00/0000"
                    />
                    <CleanInputButton
                      @click="
                        store.item.approvalCertificate.validity = undefined
                      "
                    />
                  </div>

                  <span name="item.approvalCertificate.validity" class="text-red-600 text-sm" />
                </div>

                <div class="md:col-span-1">
                  <label class="text-left flex pl-1" for="address">
                    Descartável
                  </label>
                  <div
                    class="bg-gray-50 flex border border-gray-200 rounded items-center mt-1"
                  >
                    <select
                      class="h-10 bg-gray-50 border border-gray-200 w-full"
                      v-model="store.item.reusable"
                    >
                      <option class="mt-5" value="1">SIM</option>
                      <option class="mt-5" value="0">NÃO</option>
                    </select>
                  </div>
                </div>

                <div class="flex justify-center">
                  <img
                    v-if="selectedFileUrl"
                    :src="selectedFileUrl"
                    alt="EPI"
                    class="mt-2 rounded-md w-60 max-h-60 block md:hidden"
                  />
                </div>

                <div class="md:col-span-5 mt-4 flex h-10">
                  <label
                    class="cursor-pointer bg-gray-100 hover:bg-gray-300 transition-all min-w-[8rem] pt-2.5 h-10 rounded-l-md rounded-r-none"
                  >
                    <i class="fa-solid fa-helmet-safety"></i>
                    Foto do EPI
                    <input
                      type="file"
                      class="hidden"
                      @change="handleFileChange"
                    />
                  </label>
                  <input
                    type="text"
                    style="padding-left: 10px !important"
                    class="border rounded w-full h-10 rounded-r-md rounded-l-none"
                    :value="selectedFileName"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="p-2 mt-1 mb-1" />

          <div>
            <slot />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FloatBackPage from '@/components/Buttons/FloatBackPage.vue'
import CleanInputButton from '../components/CleanInputButton.vue'
import { dataStock } from '../store'
import { useToast } from 'vue-toastification'

export default {
  name: 'FormVue',
  components: {
    CleanInputButton,
    FloatBackPage
  },
  props: {
    descriptionTitle: String
  },
  data () {
    const store = dataStock.state
    const toast = useToast()
    const fileUrl = process.env.VUE_APP_IMAGE_SERVE + 'storage/'

    return {
      store,
      fileUrl,
      toast,
      selectedFileName: undefined,
      selectedFileUrl: undefined
    }
  },
  methods: {
    handleFileChange (event) {
      const file = event.target.files[0]
      if (file) {
        const allowedExtensions = ['webp', 'png', 'gif', 'jpeg', 'jpg']
        const fileExtension = file.name.split('.').pop().toLowerCase()

        if (allowedExtensions.includes(fileExtension)) {
          this.selectedFileName = file.name
          this.selectedFileUrl = URL.createObjectURL(file)
          this.store.item.photo = file
        } else {
          this.selectedFileUrl = undefined
          this.selectedFileName = undefined
          this.store.item.photo = undefined
          return this.toast('Tipo de arquivo não é válido !', {
            toastClassName: 'toast-error'
          })
        }
      }
    }
  }
}
</script>

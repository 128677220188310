<template>
  <div>
    <PageTitleVue>
      Estoque
    </PageTitleVue>

    <ContainerVue>
      <StockTable :dataTable="dataTable" :center="true">
        <thead>
          <tr>
            <TableHeadFieldVue :text-center="false">
              <span class="mb-1.5"> Nome </span>
            </TableHeadFieldVue>

            <!-- <TableHeadFieldVue :text-center="false">
              <span class="mb-1.5"> Descrição </span>
            </TableHeadFieldVue> -->

            <TableHeadFieldVue :text-center="false">
              <span class="mb-1.5"> Qtd. Atual </span>
            </TableHeadFieldVue>

            <TableHeadFieldVue :text-center="false">
              <span class="mb-1.5"> CA </span>
            </TableHeadFieldVue>

            <TableHeadFieldVue :text-center="false">
              <span class="mb-1.5"> Validade </span>
            </TableHeadFieldVue>

            <!-- <TableHeadFieldVue :text-center="false">
              <span class="mb-1.5"> Fabricante </span>
            </TableHeadFieldVue> -->

            <TableHeadFieldVue :text-center="false">
              <span class="mb-1.5"> Fornecedor </span>
            </TableHeadFieldVue>

            <TableHeadFieldVue :textCenter="false">
              <span class="mb-1.5"> Ações </span>
            </TableHeadFieldVue>
          </tr>
        </thead>
      </StockTable>
    </ContainerVue>

    <FloatCreateVue :link="'stock-new'" />
  </div>
</template>

<script setup>
import { onBeforeMount, onMounted, ref } from 'vue'
import PageTitleVue from './../../components/Titles/PageTitle.vue'
import ContainerVue from './../../components/Container.vue'
import StockTable from './components/Table.vue'
import TableHeadFieldVue from '../../components/Tables/TableHeadField.vue'
import FloatCreateVue from '@/components/Buttons/FloatCreate.vue'
import { storeApp } from '@/store'
import StockService from './services/stock.service'
import { dataStock } from './store'
import { useToast } from 'vue-toastification'

const store = dataStock
const appStore = storeApp.state
const dataTable = ref(undefined)
const toast = useToast()

onBeforeMount(() => {
  store.commit('reset')
}),

onMounted(async () => {
  appStore.loading = true
  await getItens()
  appStore.loading = false
})

async function getItens () {
  await StockService.getAllItens().then(res => {
    dataTable.value = res.data
  }).catch(err => {
    toast(
      'Algo deu errado ao buscar estoque',
      { toastClassName: 'toast-error' }
    )
  })
}

</script>

<style></style>

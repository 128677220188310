import { http } from '@/http'
import { imgServe } from '@/imageServe'

const PATH = 'app/employee/'

class EmployeesService {
  async getAllEmployees () {
    return http.get(PATH.concat('all'))
  }

  async getAllEmployeesWithTrashed () {
    return http.get(PATH.concat('all-with-trashed'))
  }

  async verifyReport (id) {
    return http.get(PATH.concat('reports/').concat(id))
  }

  async generateEmployeeReport (id) {
    if (!id) { return false }

    return http.get(PATH.concat('reports/').concat(id))
  }

  async saveImage (newImg) {
    const headers = {
      headers: {
        BASE_PATH: process.env.VUE_APP_BASE_API_URL,
        __company: localStorage.getItem('__company')
      }
    }

    return imgServe.post('new-file', { file: newImg }, headers)
  }

  async editImage (newImg, imgPath) {
    const headers = {
      headers: {
        BASE_PATH: process.env.VUE_APP_BASE_API_URL,
        __company: localStorage.getItem('__company'),
        __company_path: imgPath
      }
    }

    return imgServe.post('replace-file', { file: newImg }, headers)
  }

  async register (employeeBody) {
    return http.post(PATH.concat('new'), employeeBody)
  }

  async getEmployeeById (id) {
    return http.get(PATH.concat(id).concat('/employee'))
  }

  async getReports () {
    return http.get(PATH.concat('reports'))
  }

  async getWithDraws () {
    return http.get(PATH.concat(''))
  }

  async editEmployee (id, employeeBody) {
    return http.post(PATH.concat(id).concat('/edit'), employeeBody)
  }

  async deleteEmployee (id) {
    return http.patch(PATH.concat(id).concat('/disable'))
  }
}

export default new EmployeesService()
export { PATH }

<template>
  <div>
    <PageTitleVue>
      Editar Funcionário
    </PageTitleVue>
    <ContainerVue>
      <Form :descriptionTitle="'Editar dados do funcionário'">
        <CreateButton @click="editEmployee" :label="'Salvar'" :icon="'fa-solid fa-user-check'" />
      </Form>
    </ContainerVue>
    <FloatBackPage />
  </div>
</template>

<script setup>
import PageTitleVue from '@/components/Titles/PageTitle.vue'
import FloatBackPage from '@/components/Buttons/FloatBackPage.vue'
import Form from './Form.vue'
import CreateButton from '@/components/Buttons/Create.vue'
import ContainerVue from '@/components/Container.vue'
import { onMounted } from 'vue'
import { dataEmployee } from '../store'
import { storeApp } from '@/store'
import EmployeesService from '../services/employees.service'
import { useRoute } from 'vue-router'
import { useToast } from 'vue-toastification'
import * as yup from './../../../validator'
import { setFormFieldsError } from '@/helpers/formFieldsHandler'

const toast = useToast()
const employeeId = useRoute()
const store = dataEmployee
const appStore = storeApp.state

const schema = yup.object().shape({
  employee: yup.object().shape({
    name: yup.string().required().label('Nome').min(5),
    function: yup.string().required().label('Função'),
    registeredSince: yup.date().required(),
    phone: yup.string().required().label('Celular'),
    document: yup.object().shape({
      cpf: yup.string().label('CPF').required()
    }),
    address: yup.object().shape({
      city: yup.string().label('Cidade').required().min(1),
      country: yup.string().label('Bairro').required().min(1)
    })
  })
})

onMounted(async () => {
  store.commit('reset')
  await getEmployees()
})

async function getEmployees () {
  appStore.loading = true
  await EmployeesService.getEmployeeById(employeeId.params.id).then(res => {
    store.state.employee.address.city = res.data.city
    store.state.employee.address.country = res.data.address
    store.state.employee.address.number = res.data.number
    store.state.employee.document.cnh = res.data.cnh
    store.state.employee.document.cpf = res.data.cpf
    store.state.employee.document.rg = res.data.rg
    store.state.employee.email = res.data.email
    store.state.employee.function = res.data.role
    store.state.employee.name = res.data.name
    store.state.employee.phone = res.data.phone
    store.state.employee.registeredSince = res.data.registered
    store.state.employee.imgPath = res.data.image
    store.state.employee.photo = null

    appStore.loading = false
  }).catch(err => {
    appStore.loading = false
    toast('Algo deu errado ao buscar funcionário!', { toastClassName: 'toast-error' })
  })
}

async function editEmployee () {
  appStore.loading = true

  const errors = []
  await schema.validate(store.state, { abortEarly: false }).catch((err) => {
    err.inner.forEach((e) => {
      errors.push({
        name: e.path,
        msg: e.message
      })
    })
  })

  if (errors.length > 0) {
    appStore.loading = false
    setFormFieldsError(errors)
    return
  }

  if (store.state.employee.photo && typeof (store.state.employee.photo) === 'object' && typeof (store.state.employee.imgPath) === 'string') {
    await EmployeesService.editImage(store.state.employee.photo, store.state.employee.imgPath).then(res => {
      store.state.employee.imgPath = res.data
      store.state.employee.photo = res.data
    }).catch(err => {
      toast('Algo deu errado ao subir arquivo!', { toastClassName: 'toast-warning' })
    })
  } else if (store.state.employee.photo && typeof (store.state.employee.photo) !== 'string') {
    await EmployeesService.saveImage(store.state.employee.photo).then(res => {
      store.state.employee.imgPath = res.data
      store.state.employee.photo = res.data
    }).catch(err => {
      toast('Algo deu errado ao subir arquivo!', { toastClassName: 'toast-warning' })
    })
  } else { store.state.employee.photo = store.state.employee.imgPath }

  await EmployeesService.editEmployee(employeeId.params.id, store.state.employee).then(res => {
    toast('Funcionário atualizado com sucesso!', { toastClassName: 'toast-success' })
    appStore.loading = false
  }).catch(err => {
    toast('Algo deu errado ao atualizar funcionário!', { toastClassName: 'toast-error' })
    appStore.loading = false
  })
}

</script>

<style></style>
